import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme, Accordion, AccordionSummary, AccordionActions,
   AccordionDetails, Typography, IconButton, useMediaQuery } from '@mui/material'
import { ExpandMore as ExpandMoreIcon, CloudDownload as DownloadIcon, } from '@mui/icons-material'
import { formatOptions } from '@src/utility'
import parse from "html-react-parser"
import FavoriteButton from '@components/FavoriteButton'
import clsx from 'clsx'
import currency from 'currency.js'
import { makeCSV } from '@src/utility'
import PropTypes from 'prop-types';

const PREFIX = 'ModelAccordion';

const classes = {
  accordionSummaryRoot: `${PREFIX}-accordionSummaryRoot`,
  accordionSummaryInnerWrapper: `${PREFIX}-accordionSummaryInnerWrapper`,
  modelName: `${PREFIX}-modelName`,
  expandIcon: `${PREFIX}-expandIcon`,
  accordionSummaryContent: `${PREFIX}-accordionSummaryContent`,
  accordionDetailsRoot: `${PREFIX}-accordionDetailsRoot`,
  progress: `${PREFIX}-progress`,
  tableWrapper: `${PREFIX}-tableWrapper`,
  optionsTableWrapper: `${PREFIX}-optionsTableWrapper`,
  table: `${PREFIX}-table`,
  nowrap: `${PREFIX}-nowrap`,
  attributeName: `${PREFIX}-attributeName`,
  tableActionsHeader: `${PREFIX}-tableActionsHeader`,
  favoriteButtonCell: `${PREFIX}-favoriteButtonCell`,
  actionsCell: `${PREFIX}-actionsCell`,
  actionsWrapper: `${PREFIX}-actionsWrapper`,
  discounts: `${PREFIX}-discounts`
};

const StyledAccordion = styled(Accordion)((
  {
    theme
  }
) => ({
  [`& .${classes.accordionSummaryRoot}`]: {
    background: theme.palette.primary.dark,
    paddingRight: 0,
    minHeight: '0 !important',
  },

  [`& .${classes.accordionSummaryInnerWrapper}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
    marginRight: theme.spacing(1)
  },

  [`& .${classes.modelName}`]: {
    color: theme.palette.common.white,
    fontFamily: 'Krub',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px', 
    lineHeight: '116.4%',
    letterSpacing: '-0.03em',
    textTransform: 'capitalize'
  },

  [`& .${classes.expandIcon}`]: {
    color: theme.palette.common.white
  },

  [`& .${classes.accordionSummaryContent}`]: {
    justifyContent: 'space-between',
    margin: '0 !important'
  },

  [`& .${classes.accordionDetailsRoot}`]: {
    padding: 0,
    flexDirection: 'column',
    alignItems: 'center',
  },

  [`& .${classes.progress}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.tableWrapper}`]: {
    overflowX: 'auto',
    width: '100%',
    color: theme.palette.text.primary,
    fontSize: '0.875rem',
  },

  [`& .${classes.optionsTableWrapper}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.table}`]: {
    width: '100%',
    borderCollapse: 'collapse',
    '& tbody': {
      '& tr:nth-child(2n)': {
        background: 'rgb(217, 234, 211)',
      },
      '& tr:nth-child(2n + 1)': {
        background: 'rgb(255, 542, 204)',
      },
    },
    '& td, & th': {
      textAlign: 'center',
      border: '1px solid rgb(220, 219, 216)',
    }
  },

  [`& .${classes.nowrap}`]: {
    whiteSpace: 'nowrap',
  },

  [`& .${classes.attributeName}`]: {
    fontFamily: 'Krub',
    fontStyle: 'normal',
  },

  [`& .${classes.tableActionsHeader}`]: {
    width: 84,
    visibility: 'hidden',
    border: 'none !important'
  },

  [`& .${classes.favoriteButtonCell}`]: {
    textAlign: 'left !important',
    paddingLeft: theme.spacing(1),
    background: theme.palette.common.white,
    border: 'none !important'
  },

  [`& .${classes.actionsCell}`]: {

  },

  [`& .${classes.actionsWrapper}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  [`& .${classes.discounts}`]: {
    fontSize: '14px',
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.accent, 
    fontWeight: 800
  }
}));

const OutputButtons = (props) => {
  const { model, attributeNamesSet, options, expanded} = props
  const [ fileDownloadUrl, setFileDownloadUrl ] = useState(null)
  const doFileDownload = useRef(null)

  const download = (event) => {
    event.preventDefault();
    let contents = [];
    contents.push([model.name.toUpperCase()])
    contents.push([''])
    contents.push ([...attributeNamesSet, "Price"]);

    model.products.nodes.forEach(row => {
      const attributeValues = [...attributeNamesSet].map(name => {
        const foundOption = row.attributes.nodes.find(attribute => attribute.name === name)
        return foundOption?.options?.[0] || '-'
      })

      contents.push([ ...attributeValues.map(value => `[${value}]`), row.price ])
      contents.push([''])
    });

    if(options?.length) {
      contents.push(["OPTIONS"])
      contents.push([''])
      contents.push(["Name", "Price"])
      options.forEach(option => {
        contents.push([option.optionName, currency(option.optionPrice).format()])
      })
    }

    const output = makeCSV(contents);
    // Download it
    const blob = new Blob([output]);
    const fileDownloadUrl = URL.createObjectURL(blob);
    setFileDownloadUrl(fileDownloadUrl);    
  }

  useEffect(() => {
    if(!fileDownloadUrl || !doFileDownload.current) return

    doFileDownload.current.click(); 
    URL.revokeObjectURL(fileDownloadUrl);  // free up storage--no longer needed.
    setFileDownloadUrl("")
  }, [fileDownloadUrl])


  return (
    <AccordionActions onClick={e => e.stopPropagation()} style={{ backgroundColor: '#ffffff'}}>
      <IconButton
        title="download csv file"
        aria-label="download csv file"
        size="small"
        color="primary"
        onClick={download}
        disabled={!expanded || typeof window == 'undefined' || model.products?.nodes?.length < 1}
      >
        <DownloadIcon />
      </IconButton>
      <a 
        style={{
          visibility: 'hidden',
          display: 'none'
        }}
        download={`${model.name}-modifications-pricelist.csv`}
        href={fileDownloadUrl}
        ref={doFileDownload}
      >
        download it
      </a>
      {/* <IconButton 
      size="small" 
      color="primary" 
      component="a"
      disabled={!expanded} 
      target="_blank"
      // onClick={() => typeof window !== 'undefined' && window.open(url, '_blank')}
      >
      <PrintIcon />
    </IconButton> */}
  </AccordionActions>
  )
}

function ModelAccordion({ model, locationHash, attributeNamesSet }) {

  const theme = useTheme()
  const matchesDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const accordionRef = useRef(null);
  const [ expanded, setExpanded ] = useState(false)
  const [ elevatorOptions, setElevatorOptions ] = useState([])

  useEffect(() => {
    const clearOptionsArray = formatOptions(model.elevatorOptions)
      .map(option => ({ ...option, units: 0 }))

    if(!clearOptionsArray.length) {
      return
    }

    setElevatorOptions(clearOptionsArray)
  }, [model.elevatorOptions])

  useEffect(() => {
    const idFromHash = locationHash?.replace('#', '');
    const shouldExpand = (idFromHash === model.id) || (!idFromHash && matchesDesktop);
    if(shouldExpand) {
      setExpanded(true);
      if(accordionRef.current) {
        setTimeout(() => {
          accordionRef.current.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" });
        }, 100);
      }
    }
  }, [ matchesDesktop, locationHash ])


  
  const handleChange = () => setExpanded(!expanded)


  return (
    <StyledAccordion ref={accordionRef} key={model.id} expanded={expanded} onChange={handleChange}>
      <AccordionSummary
        classes={{ root: classes.accordionSummaryRoot, content: classes.accordionSummaryContent}}
        aria-controls="Model content"
        id="Model-header"
      >
        <div className={classes.accordionSummaryInnerWrapper}>
          <h2 className={classes.modelName}>{model.name}</h2>
          <IconButton size="small">
            <ExpandMoreIcon 
              className={classes.expandIcon} 
              style={{transform: expanded ? 'rotate(180deg)' : 'none'}}
            />
          </IconButton>
        </div>
        <OutputButtons model={model} attributeNamesSet={attributeNamesSet} options={elevatorOptions} expanded={expanded} />
      </AccordionSummary>
      <AccordionDetails classes={{root: classes.accordionDetailsRoot}}>
        <div className={classes.tableWrapper}>
          <table className={classes.table}>
            <thead>
              <tr>
                {
                  [...attributeNamesSet].map(attributeName => (
                    <th key={attributeName} className={classes.attributeName}>{attributeName}</th>
                  ))
                }
                {
                  (!!attributeNamesSet.size || !!model.grouped_products_nodes?.length) &&
                  <>
                    <th className={classes.attributeName}>Price</th>
                    <th className={classes.tableActionsHeader}>Actions</th>
                  </>
                }
              </tr>
            </thead>
            <tbody>
              {
                model.products.nodes
                  .sort((modificationA, modificationB) => currency(modificationA.price).subtract(modificationB.price))
                  .map(modification => {
                  return (
                    <tr key={modification.id}>
                      {
                        [...attributeNamesSet].map(attributeName => {
                          const foundAttribute = modification.attributes.nodes.find(attribute => attribute.name === attributeName)
                          return (
                            <td key={attributeName} className={classes.nowrap}>{
                              foundAttribute ?
                                foundAttribute.options[0] :
                                '-'
                            }</td>
                          )
                        })
                      }
                      <td>{modification.price ? parse(modification.price) : '-'}</td>
                      <td className={classes.favoriteButtonCell}>
                        <FavoriteButton
                          product={modification}
                          options={model.elevatorOptions}
                          size="small"
                          defaultColor="rgba(0, 0, 0, 0.26)"
                          activeColor={theme.palette.primary.main} />
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        {
          elevatorOptions?.length ?
            <div className={clsx(classes.tableWrapper, classes.optionsTableWrapper)}>
              <Typography 
                variant="body1"
                className={classes.discounts} 
                paragraph
              >
                Discounts May Apply
              </Typography>
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th className={classes.attributeName}>Option</th>
                    <th className={classes.attributeName}>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    elevatorOptions
                      .sort((optionA, optionB) => optionA.optionPrice - optionB.optionPrice)
                      .map(option => {
                        return (
                          <tr key={option.optionName}>
                            <td>{option.optionName}</td>
                            <td>
                              {`$${option.optionPrice}`}
                            </td>
                          </tr>
                        )
                    })
                  }
                </tbody>
              </table>
            </div> : null
        }
      </AccordionDetails>
    </StyledAccordion>
  );
}

const attributePropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
});

const productPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  attributes: PropTypes.shape({
    nodes: PropTypes.arrayOf(attributePropTypes).isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  productCategories: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  price: PropTypes.string.isRequired,
});

const elevatorOptionsPropTypes = PropTypes.shape({
  optionName: PropTypes.string,
  optionPrice: PropTypes.number,
});

const modelPropTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  products: PropTypes.shape({
    nodes: PropTypes.arrayOf(productPropTypes).isRequired,
  }).isRequired,
  productCategories: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  modelDetails: PropTypes.shape({
    productorder: PropTypes.number,
  }).isRequired,
  elevatorOptions: PropTypes.objectOf(elevatorOptionsPropTypes),
};

ModelAccordion.propTypes = {
  model: PropTypes.shape(modelPropTypes).isRequired,
  locationHash: PropTypes.string,
  attributeNamesSet: PropTypes.instanceOf(Set).isRequired,
};

export default ModelAccordion;