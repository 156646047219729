import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import SEO from "@components/seo"
import { graphql } from 'gatsby'
import TitleHeader from '@components/TitleHeader'
import { Button } from 'gatsby-theme-material-ui'
import { Grid, Box, Typography, Container } from '@mui/material'
import ModelAccordion from '@components/AllPriceLists/ModelAccordion'
import { SeenProductsStateContext } from '@context/seenProductsContext'
import SimpleSlider from '@components/ProductCardDetailed/SimpleSlider';
import Breadcrumbs from '@components/Breadcrumbs';

const PREFIX = 'AllPriceListsPage';

const classes = {
  container: `${PREFIX}-container`
};

const Root = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.container}`]: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  }
}));

const inclineConveyorModel = {
  "id": '0',
  "name": "Incline Conveyors",
  "productCategories": {
    "nodes": [
      {
        "name": "Incline Conveyors"
      }
    ]
  },
  "products": {
    "nodes": [
      {
        "id": '0',
        "attributes": {
          "nodes": [
            {
              "id": "cGFfbW9kZWwtbnVtYmVyOjU3MTM6cGFfbW9kZWwtbnVtYmVy",
              "name": "Model no.",
              "options": [
                "1210 IC"
              ]
            },
          ]
        },
        "name": "1210 IC",
        "productCategories": {
          "nodes": [
            {
              "name": "Incline Conveyors"
            }
          ]
        },
        "price": "$12,900.00"
      },
      {
        "id": '1',
        "attributes": {
          "nodes": [
            {
              "id": "cGFfbW9kZWwtbnVtYmVyOjU3MTM6cGFfbW9kZWwtbnVtYmVy",
              "name": "Model no.",
              "options": [
                "1610 IC"
              ]
            },
          ]
        },
        "name": "1610 IC",
        "productCategories": {
          "nodes": [
            {
              "name": "Incline Conveyors"
            }
          ]
        },
        "price": "$14,800.00"
      },
      {
        "id": '2',
        "attributes": {
          "nodes": [
            {
              "id": "cGFfbW9kZWwtbnVtYmVyOjU3MTM6cGFfbW9kZWwtbnVtYmVy",
              "name": "Model no.",
              "options": [
                "1614 IC"
              ]
            },
          ]
        },
        "name": "1614 IC",
        "productCategories": {
          "nodes": [
            {
              "name": "Incline Conveyors"
            }
          ]
        },
        "price": "$17,600.00"
      },
      {
        "id": '3',
        "attributes": {
          "nodes": [
            {
              "id": "cGFfbW9kZWwtbnVtYmVyOjU3MTM6cGFfbW9kZWwtbnVtYmVy",
              "name": "Model no.",
              "options": [
                "1219 IC"
              ]
            },
          ]
        },
        "name": "1219 IC",
        "productCategories": {
          "nodes": [
            {
              "name": "Incline Conveyors"
            }
          ]
        },
        "price": "$16,500.00"
      },
      {
        "id": '4',
        "attributes": {
          "nodes": [
            {
              "id": "cGFfbW9kZWwtbnVtYmVyOjU3MTM6cGFfbW9kZWwtbnVtYmVy",
              "name": "Model no.",
              "options": [
                "1621 IC"
              ]
            },
          ]
        },
        "name": "1621 IC",
        "productCategories": {
          "nodes": [
            {
              "name": "Incline Conveyors"
            }
          ]
        },
        "price": "$24,100.00"
      },
      {
        "id": '5',
        "attributes": {
          "nodes": [
            {
              "id": "cGFfbW9kZWwtbnVtYmVyOjU3MTM6cGFfbW9kZWwtbnVtYmVy",
              "name": "Model no.",
              "options": [
                "2721 IC"
              ]
            },
          ]
        },
        "name": "2721 IC",
        "productCategories": {
          "nodes": [
            {
              "name": "Incline Conveyors"
            }
          ]
        },
        "price": "$34,200.00"
      },
    ]
  },
  "modelDetails": {
    "productorder": 13
  },
  "elevatorOptions": {}
};

function AllPriceListsPage({ location, data: { allWpProduct: { models }, placeholderLocalFile }}) {
  const allModels = models.concat(inclineConveyorModel);
  const placeholderImage = {
    localFile: placeholderLocalFile,
    altText: 'no image',
    id: 0.01
  }

  const seenProducts = useContext(SeenProductsStateContext)
  
  return (
    (<>
      <SEO 
        title="All price list of the Farm Equipments catalogue" 
        description="Farm Equipment Plus specializes in grain handling and grain drying equipment. If you need help with a project or any particular equipment, please contact us. We may have inventory not yet listed on our website." 
      />
      <Root maxWidth="md" disableGutters>
        <div className={classes.container}>
          <Breadcrumbs 
            links={
              [
                { name: 'Home', path: `/` },
                { name: `Price list`, path: `/all-price-lists`, isDisabled: true }
              ]
            } 
          />
          <TitleHeader
            title='Grain equipment'
            subtitle="Price list"
            additionalContent={<Button to="/my-price-list" variant="contained" color="secondary">view my price list</Button>}
          />
          <Grid container spacing={2}>
            {
              allModels
              .sort((model1, model2) => (model1.modelDetails?.productorder || 0) - (model2.modelDetails?.productorder || 0))
              .map(model => {
                const attributeNamesSet = model.products.nodes.reduce(
                  (result, product) => {
                    const newResult = result;
                    product.attributes.nodes.forEach(attribute => newResult.add(attribute.name));
                    return newResult;
                  },
                  new Set()
                )
                return (
                  <Grid key={model.id} item xs={12} sm={6} md={4}>
                    <ModelAccordion 
                      key={model.id} 
                      model={model} 
                      attributeNamesSet={attributeNamesSet} 
                      locationHash={location.hash} 
                    />
                  </Grid>
                )
              })
            }
          </Grid>
          <Box mt={4}>
            {
              (seenProducts.length > 0) && (
                <>
                  <Typography component="h2" variant="body2" style={{ fontSize: 12, marginBottom: 6 }}>
                    seen products
                  </Typography>
                  <SimpleSlider
                    products={seenProducts}
                    placeholderImage={placeholderImage}
                  />
                </>
              )
            }
          </Box>
        </div>
      </Root>
    </>)
  );
}

export default AllPriceListsPage;

export const pageQuery = graphql`
  query PriceList {
    placeholderLocalFile: file(relativePath: {eq: "woocommerce-placeholder-1024.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 80, width: 600, layout: CONSTRAINED)
      }
    }
    allWpProduct(
      filter: {productCategories: {nodes: {elemMatch: {name: {ne: "Grain Augers"}}}}, status: {eq: "publish"}, productTypes: {nodes: {elemMatch: {name: {eq: "grouped"}}}}}
    ) {
      models: nodes {
        id
        name
        productCategories {
          nodes {
            name
          }
        }
        ... on WpGroupProduct {
          id
          products {
            nodes {
              id
              attributes {
                nodes {
                  id
                  name
                  options
                }
              }
              name
              productCategories {
                nodes {
                  name
                }
              }
              ... on WpSimpleProduct {
                price
              }
            }
          }
        }
        modelDetails {
          productorder
        }
        elevatorOptions {
          option1 {
            optionName
            optionPrice
          }
          option2 {
            optionName
            optionPrice
          }
          option3 {
            optionName
            optionPrice
          }
          option4 {
            optionName
            optionPrice
          }
          option5 {
            optionName
            optionPrice
          }
        }
      }
    }
  }
`